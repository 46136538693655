<template lang="pug">
    .root.text-right
        .jumbotron.pull-right()
            .container-fluid.text-right
                .label
                    h2.moto-text דף זה אינו קיים
        .container.pt-3
            .err-box-container
                .header-text
                    h1.big-text שגיאה, 404
                .body-text
                    h3.big-text.orange-text &nbsp;&nbsp;
                        | הדף שחיפשתם אינו קיים במערכת.
                    h3.big-text.orange-text   עימכם הסליחה


</template>


<style lang="scss">
    .container {
        height: 100%;

        .err-box-container {

            display: flex;
            justify-content: center;
            text-align: center;
            flex-direction: column;


            h1.big-text {
                font-size: 72px;
            }

            h3.big-text {
                font-size: 36px;
            }


        }
    }

</style>


<script lang="js">
    export default {
        name: '404'
    }
</script>
